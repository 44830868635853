@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

.App {
	height: 100vh;
	text-transform: none !important;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	height: 100%;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Webkit-based browsers (Chrome, Safari, Opera) */
::-webkit-scrollbar {
	width: 12px; /* Wider scrollbar for better visibility */
	height: 12px; /* Wider scrollbar for better visibility */
}

::-webkit-scrollbar-track {
	background-color: #f0f4c3; /* Lighter background for better contrast */
	border-radius: 10px; /* More rounded corners for a smoother look */
}

::-webkit-scrollbar-thumb {
	background-color: #dcf032; /* Gradient for a cool effect */
	background-image: linear-gradient(to right, #dcf032 0%, #c0ca33 100%);
	border-radius: 10px; /* More rounded corners for a smoother look */
}

::-webkit-scrollbar-thumb:hover {
	background-color: #b0a32f; /* Darken the gradient on hover */
}

/* Firefox */
* {
	scrollbar-width: thin; /* "auto" or "thin" */
	scrollbar-color: #c0ca33 #f0f4c3; /* Track and thumb colors to match gradient */
}

/* CustomPhoneInput.css */
.PhoneInputInput,
.PhoneInputCountry {
	border: 1px solid #29273e; /* MUI primary color, adjust as needed */
	padding: 18.5px 14px;
	font-size: 1rem;
	border-radius: 0.7rem;
	outline: none;
	background-color: transparent; /* MUI light grey, adjust as needed */
	color: inherit !important;
}

.PhoneInputInput:focus,
.PhoneInputCountrySelect:focus {
	border-color: #dcf032; /* MUI primary color, adjust as needed */
	background-color: transparent; /* MUI light grey, adjust as needed */
}

/* Optional: Add MUI-like hover effect */
.PhoneInputInput:hover,
.PhoneInputCountrySelect:hover {
	border-color: white;
}

/* Extend styles for the country dropdown */
.PhoneInputCountrySelect {
	border: 1px solid #29273e !important; /* MUI primary color, adjust as needed */
	border-radius: 0.7rem !important;
	padding: 18.5px 14px !important;
	font-size: 1rem !important;
	border-radius: 0.7rem !important;
	outline: none !important;
	background-color: #29273e !important;
	color: #dcf032;
	appearance: none !important; /* Removes default browser dropdown arrow */
	cursor: pointer !important; /* Changes cursor to pointer to indicate it's clickable */
}

/* Add custom arrow indicator for the dropdown */
.PhoneInputCountrySelect::after {
	content: "▼" !important; /* Unicode character for down arrow */
	font-size: 0.8rem !important;
	position: absolute !important;
	right: 15px !important;
	top: 50% !important;
	transform: translateY(-50%) !important;
	pointer-events: none; /* Prevents the arrow from being clickable */
	color: white; /* Adjust as needed */
}
